import * as React from "react"

import Layout from "../../components/layout"
import Menuicons from "../../components/menuicons"
import Banner_afiliate from "../../components/banner_afiliate"
import Carousel from "../../components/carousel"
import Seo from "../../components/seo"
import Slidernoticias from "../../components/slidernoticias"
import Sliderblog from "../../components/sliderblog"
import Slider from "../../components/slider"

const IndexPage = () => (
  <>
    <Layout site="personas" page="/" className="mitexto">
      <Seo title="Personas" />
      <Carousel
        site="personas"
        style={{ margin: 0, display: "block" }}
      ></Carousel>
      <Slider site="personas" />
      <div className="bg-default text-gray-600 work-sans leading-normal text-base tracking-normal">
        <section className="mitexto bg-white py-2">
          <Banner_afiliate site="personas" />
          <Menuicons site="personas" />
          <Slidernoticias site="personas" />
        </section>
        <section
          className="bg-white tamano_imagen_blog mitexto"
          style={{ height: "23rem" }}
        >
          <a href={"/blogs"}>
            <Sliderblog />
          </a>
        </section>
      </div>
    </Layout>
  </>
)
export default IndexPage
