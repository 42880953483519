import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Helmet from "react-helmet"
import "./blog.css"
import Categorianoticia from "./categorianoticia"

const Noticias = ({ site }) => {
  ;<Helmet>
    <script
      src={withPrefix("js/code.jquery.com_jquery-1.12.4.min.js")}
    ></script>
    <script src={withPrefix("js/paginador.js")}></script>
  </Helmet>
  const query = useStaticQuery(graphql`
    query noticias_ss {
     allStrapiNoticias(sort: {order: DESC, fields: created_at}) {
        edges {
          node {
            Contenido
            Imagen {
              url
            }
            Estado
            Palabra_clave
            Categoria
            Titulo
            Slug
            id
            Site
          }
        }
      }
    }
  `)

  let url_global = process.env.GATSBY_API_URL
  let projects = query.allStrapiNoticias.edges

  return (
    <>
      <div className="mitexto">
        <div className="relative alinear_movil_blog mx-auto container justify-items-center lg:grid grid-cols-3 gap-4 mt-64 pt-32">
          <div className="col-span-2 orden_blog">
            <div className="relative centrado_vertical lg:inline-grid gap-4 grid-cols-2">
              {/* PARA VERSION MOVIL */}

              {/* <div className="hidden alinear_categoria categoria_oculta">
                <Categorianoticia site={site} />
              </div> */}

              {/* PARA VERSION MOVIL */}
              
              {projects.map(({ node: project }, index) => {
                if (project.Estado === "Mostrar" && project.Site === site) {
                  return (
                    <div className="list-wrapper" key={index}>
                      <div className="list-item" style={{ display: "block" }}>
                        <div className="square_sombra transform hover:-translate-y-1 hover:scale-110 transition duration-500">
                          <a
                            href={"/noticias/detalle/?" + project.Slug}
                            target="_self"
                          >
                            <div key={index} className="square_sombra mitexto">
                              {project.Imagen[0]?.url !== undefined && (
                                <img
                                  className="img_dos mask"
                                  src={url_global + "" + project.Imagen[0]?.url}
                                  alt={project.Titulo}
                                  title={project.Titulo}
                                />
                              )}

                              {project.Imagen[0]?.url === undefined && (
                                <img
                                  key={index}
                                  className="img_dos mask"
                                  src={
                                    url_global +
                                    "/uploads/Logo_eps_delagente_noticias_2_ba8e4c41eb.jpg"
                                  }
                                  alt={project.Titulo}
                                  title={project.Titulo}
                                />
                              )}

                              <div className="h1 mitexto mitexto_color color-texto-parrafo text-poppins-bold">
                                {project.Titulo}
                              </div>
                              <div
                                className="mitexto_color ancho_tarjetas_texto"
                                style={{
                                  position: "relative",
                                  top: "1rem",
                                  overflowY: "hidden",
                                  overflowX: "hidden",
                                  gridArea: "1/1",
                                  maxHeight: "100px",
                                  maxWidth: "321px",
                                  paddingLeft: "26px",
                                  textAlign: "justify",
                                  marginBottom: "2rem",
                                }}
                                layout="fullWidth"
                                dangerouslySetInnerHTML={{
                                  __html: project.Contenido,
                                }}
                              />

                              <p
                                className="green-secundary text-poppins-bold"
                                style={{ paddingLeft: "7%", fontSize: "14px" }}
                              >
                                {project.Categoria}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>

            <div id="pagination-container" className="relative pb-8 pt-8"></div>
          </div>

          <div className="alinear_categoria">
            <Categorianoticia site={site} />
          </div>
        </div>
      </div>
    </>
  )
}

Noticias.propTypes = {
  siteTitle: PropTypes.string,
}

Noticias.defaultProps = {
  siteTitle: ``,
}

export default Noticias
