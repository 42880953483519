import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./blog.css"

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index
}
const CategoriasNoticias = props => {
  const query = useStaticQuery(graphql`
    query CategoriasNoticias {
      allStrapiNoticias {
        edges {
          node {
            Contenido
            Estado
            Imagen {
              url
            }
            Palabra_clave
            Categoria
            Titulo
            id
            Site
          }
        }
      }
    }
  `)

  const projects = query.allStrapiNoticias.edges

  let currentSite = null

  if (typeof window !== "undefined") {
    currentSite = window.localStorage.getItem("currentSite")
  }

  let categorias = []

  projects.map(({ node: row }) => {
    if (row.Categoria && row.Estado === "Mostrar" && row.Site === currentSite) {
      categorias.push(row.Categoria)
    }
  })

  var uniqueCategorias = categorias.filter(onlyUnique)

  return (
    <>
      <div
        key={"index"}
        className="alinear_categoria mx-auto container lg:inline-grid gap-4 grid-cols-2"
      >
        <div
          key={"index-2"}
          className="encabezado_blog encabezado_categoria_movil"
          style={{
            width: "356px",
            backgroundColor: "#c5cc0c",
            borderRadius: "30px 30px 10px 30px",
            padding: "15px",
            color: "white",
            textAlign: "-webkit-center",
          }}
        >
          <p className="text-poppins-bold">
            Categorias <span className="text-poppins-regular">Noticias</span>
          </p>
        </div>
        <br></br>
        <div key={"index-3"} className="card mitexto mitexto_color">
          <ul className="text-left" id="searchResults">
            {uniqueCategorias.map((project, index) => {
              return (
                <div
                  key={index}
                  className="text-poppins-bold color-texto-parrafo alto_contraste_categoria"
                >
                  <a href={"/noticias/categorias/?" + project} target="_self">
                    <li className="green">
                      <span className="name mitexto mitexto_color">
                        {project}
                      </span>
                    </li>
                  </a>
                </div>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

CategoriasNoticias.propTypes = {
  siteTitle: PropTypes.string,
}

CategoriasNoticias.defaultProps = {
  siteTitle: ``,
}

export default CategoriasNoticias
