import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./noticias.css"

import Noticia from "../../components/noticias"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"
import BotonFlotante from "../../components/botonflotante"
import ModalPopup from "../../components/modal_popup"

const Noticias = () => {
  const query = useStaticQuery(graphql`
    query Detalles_noticias {
      allStrapiPages {
        edges {
          node {
            content
            site
            slug
          }
        }
      }
    }
  `)
  const projects = query.allStrapiPages.edges

  let currentSite = null

  if (typeof window !== "undefined") {
    currentSite = window.localStorage.getItem("currentSite")
  }

  return (
    <>
      {projects.map(({ node: project }, index) => {
        if (project.slug === "noticias") {
          return (
            <div key={index}>
              <Accesibilidad />
              <Header site={currentSite || "personas"} />
              <Seo title="Personas" />
              <ModalPopup site={currentSite} slug={"noticias"} />

              <div
                className="mitexto_color"
                layout="fullWidth"
                dangerouslySetInnerHTML={{
                  __html: project.content,
                }}
              />

              <BotonFlotante />

              <Noticia site={currentSite} />
            </div>
          )
        }
      })}
      <Footer site={currentSite} />
    </>
  )
}

Noticias.propTypes = {
  siteTitle: PropTypes.string,
}

Noticias.defaultProps = {
  siteTitle: ``,
}

export default Noticias
