import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import BotonFlotante from "../../components/botonflotante"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"
import ModalPopup from "../../components/modal_popup"

const Detallecitasmedicas = props => {
  const query = useStaticQuery(graphql`
    query Detallecitasmedicas {
      allStrapiPages {
        edges {
          node {
            content
            site
            slug
          }
        }
      }
    }
  `)

  const projects = query.allStrapiPages.edges

  const host = "https://citasalud.epsdelagente.com.co/"

  var urlIframe = host
  var mobile = false

  if (typeof window !== "undefined") {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      mobile = true
    }
  }

  return (
    <>
      {projects.map(({ node: project }, index) => {
        if (project.slug === "citas-medicas") {
          return (
            <div key={`citas-${index}`}>
              <Accesibilidad />
              <Header site="personas" />
              <Seo title="Personas" />
              <ModalPopup site="personas" slug={"citas-medicas"} />
              <div
                className="mitexto_color"
                layout="fullWidth"
                dangerouslySetInnerHTML={{
                  __html: project.content,
                }}
              />

              {mobile && (
                <di>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <iframe
                    src={urlIframe}
                    // height="700"
                    // width="380"
                    className="block mx-auto embed-responsive-item alto_iframe_servicio_linea xl:w-2/3 lg:w-3/4 md:w-3/4 sm:w-full"
                  ></iframe>
                </di>
              )}

              {!mobile && (
                <div className="margen_citas_medicas_texto mx-auto xl:w-2/3">
                  <iframe
                    src={urlIframe}
                    // height="600"
                    // width="1024"
                    className="block mx-auto embed-responsive-item alto_iframe_servicio_linea xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-full"
                  ></iframe>
                </div>
              )}
              <br></br>
              <br></br>
              <br></br>

              <BotonFlotante
                site="personas"
                style={{ margin: 0, display: "block" }}
              ></BotonFlotante>

              <Footer />
            </div>
          )
        }
      })}
    </>
  )
}

Detallecitasmedicas.propTypes = {
  siteTitle: PropTypes.string,
}

Detallecitasmedicas.defaultProps = {
  siteTitle: ``,
}

export default Detallecitasmedicas
