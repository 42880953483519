import * as React from "react"
import Layout from "../../components/layout"
import Menuicons from "../../components/menuicons"
import Banner_afiliate from "../../components/banner_afiliate"
import Slidernoticias from "../../components/slidernoticias"
import Seo from "../../components/seo"
import Carousel from "../../components/carousel"
import Sliderblog from "../../components/sliderblog"
import Slider from "../../components/slider"

const IndexPage = ({ data }) => {
  
  return (
    <>
      <Layout site="empresas" page="/" className="mitexto">
        <Seo title="Empresas" />
        <Carousel
          site="empresas"
          style={{ margin: 0, display: "block" }}
        ></Carousel>
        <Slider site="empresas" />
        <div className="bg-default text-gray-600 work-sans leading-normal text-base tracking-normal">
          <section className="bg-white py-2 mitexto">
            <Banner_afiliate site="empresas" />
            <Menuicons site="empresas" />

            <Slidernoticias site="empresas" />
          </section>

          <section
            className="bg-white tamano_imagen_blog mitexto"
            style={{ height: "23rem" }}
          >
            <a href={"/blogs"}>
              <Sliderblog />
            </a>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
