import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./blog.css"
import Blog from "../../components/blogs"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"
import BotonFlotante from "../../components/botonflotante"
import ModalPopup from "../../components/modal_popup"

const Blogs = props => {
  const query = useStaticQuery(graphql`
    query Detalleblog {
      allStrapiPages {
        edges {
          node {
            content
            site
            slug
          }
        }
      }
    }
  `)
  const projects = query.allStrapiPages.edges

  let currentSite = null

  if (typeof window !== "undefined") {
    currentSite = window.localStorage.getItem("currentSite")
  }

  return (
    <>
      {projects.map(({ node: project }, index) => {
        if (project.site === null && project.slug === "blogs") {
          return (
            <div key={index}>
              {/* <Layout> */}
              <Accesibilidad />
              <Header site={currentSite || "personas"} />
              <Seo title="Personas" />
              <ModalPopup site={currentSite} slug={"blogs"} />
              <div
                className="mitexto_color"
                layout="fullWidth"
                dangerouslySetInnerHTML={{
                  __html: project.content,
                }}
              />
              <BotonFlotante />

              <Blog site={currentSite}></Blog>

              {/* </Layout> */}
            </div>
          )
        }
      })}
      <Footer site={currentSite} />
    </>
  )
}

Blogs.propTypes = {
  siteTitle: PropTypes.string,
}

Blogs.defaultProps = {
  siteTitle: ``,
}

export default Blogs
